
table.ui.table{
  text-align: center;
}

.clientList__table {
  padding-top: 50px;
  padding-bottom: 50px;
  /* background-color: #fafafa; */
}

@media (min-width: 992px) {
  .clientList_container.container-md{
    max-width: 1300px;
  }
}
