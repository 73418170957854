.color-nav{
    background-color: rgba(255, 255, 255, 0.842) !important;

}
.color-nav a{
    color: black !important;
} 



.map-icon:hover{
    height: 120px;
    width: 120px;
    top:220px !important;
    left: 970px !important;
    stroke: white;
    cursor: pointer;
    
}

#nous-joindre{
    margin-left: auto;
    margin-right: auto;
}


@media only screen and (max-width: 1560px){
    #nous-joindre{
        margin-left: 35%;
    }
}





@media (max-width: 1000px){
    #nous-joindre{

        margin-top: 0 !important;
        margin-left: unset;

    }

    #map{
        display: none !important;

    }

    svg{
        display: none !important;
    }

    #horaire {
        margin-top: 150px;
        position: relative !important;
        margin-left: auto;
        margin-right: auto;

        max-width: unset !important;
        min-width: unset !important;
        width: unset !important;
        top: unset !important;
        left: unset !important;
        box-shadow: unset !important;
        padding-bottom: 10px;
        box-shadow: unset;

    }
  }