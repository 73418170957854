.footer {
  background-color: #999999;
  text-align: center;
  padding-top: 20px;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
}

