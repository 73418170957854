

 .border-divider-left{
  border-right: 2px solid orange;
  
}



@media (max-width: 1200px){

  .wrapper{
    flex-wrap: wrap;
  }
  
  .border-divider-left{
    border-bottom: 2px solid orange;
    border-right: unset !important;    
  }
}
