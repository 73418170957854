
/* Override CSS */

.select:hover{
    opacity: 0.5;
}



@media only screen and (min-width: 768px)  {

    #basic-navbar-nav{
        text-align: center !important;
    }

}


@media only screen and (min-width: 992px) {

    .banner-title-top{
        margin:  300px 0  100px 0;
    }
    

}


@media only screen and (max-width: 992px) {

  
    .navbar.navbar-light .navbar-toggler {
        border: unset !important;
        border-color: unset !important;
    }
    
    .navbar.navbar-light .navbar-toggler .navbar-toggler-icon{
        background-image: url("../src/assets/svg/hamburger.svg") !important;
    }
    
    .navbar.navbar-light .navbar-toggler .navbar-toggler-icon:hover{
        opacity: 0.4;
    }


}




@media only screen and (max-width: 767px) {

 

 

    
    
    .navbar.navbar-light .navbar-nav{
        max-width: unset !important;
        text-align: center !important;
    }

    .navbar.navbar-light.is-sticky #basic-navbar-nav{
        width: 100% !important;
        right: 0 !important;
        top: 85px !important;

    }
}