.header {
  background-color: #999999;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
}

.header__logo {
  object-fit: contain;
  width: 60px;
  margin: 10px 75px 10px 70px;
}

.header__searchInput {
  border: none;
  width: 100%;
  object-fit: contain;
}

.header__searchButton {
  display: flex;
  color: orange;
  background-color: orange;
}

.header__menuButton {
  padding: 10px;
  display: flex;
}

.header__search {
  display: flex;
  flex: 1;
}

.header__nav {
  display: flex;
  margin-right: 15px;
}

.header_navMenu {
  align-items: center;
  margin-left: 20px;
  margin-right: 15px;
  font-size: 30px;
  font-weight: 100;
  color: #3c3c3c;
}


