/*
@File: Exolot - Multipurpose Landing Page HTML5 Template Styles

* This file contains the styling for the actual tepmlate, this
is the file you need to edit to change the look of the
tepmlate.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

==== INDEX PAGE CSS

** - Default CSS
** - Preloader Area CSS
** - Navbar Area CSS
** - Main Banner Area CSS
** - Subscribe Area CSS
** - Welcome Area CSS
** - About Area CSS
** - Services Area CSS
** - Team Area CSS
** - Portfolio Area CSS
** - Features Area CSS
** - Feedback Area CSS
** - Pricing Area CSS
** - Blog Area CSS
** - Work With Us Area CSS
** - Partner Area CSS
** - Contact Area CSS
** - Footer Area CSS
** - Right Side Modal CSS
** - Go Top CSS

*/
/*================================================
Default CSS
=================================================*/
$family1: unquote("Poppins");
$family2: unquote("Ubuntu");
$font_family1: "Poppins", sans-serif;
$font_family2: "Ubuntu", sans-serif;
$template_color: orange;
$template_hover_color: #8adb34;

@import url("https://fonts.googleapis.com/css?family=#{$family1}:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i");
@import url("https://fonts.googleapis.com/css?family=#{$family2}:300,300i,400,400i,500,500i,700,700i");

body {
    padding: 0;
    margin: 0;
    font-display: $font_family1;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.p,
h1,
h2,
h3,
h4,
h5,
h6
p {
    font-family: $font_family2;
}
.d-table {
    width: 100%;
    height: 100%;
}
.d-table-cell {
    vertical-align: middle;
}
.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}
a {
    display: inline-block;
    outline: 0 !important;
    text-decoration: none !important;
    transition: 0.4s;
}
p {
    font: {
        weight: 300;
        size: 15px;
    }
    line-height: 1.8;
    color: #999999;
}
img {
    max-width: 100%;
}
.bg-f9f9f9 {
    background: #f9f9f9;
}

/*btn*/
.btn {
    font: {
        weight: 600;
        size: 15px;
    }
    border: none;
    padding: 10px 33px;
    line-height: 1.5;
    text-transform: capitalize;
    border-radius: 50px;

    &:focus {
        outline: 0;
    }
}
button:focus {
    outline: 0;
}
.btn-primary {
    color: #ffffff;
    background-color: $template_color;
    border-color: $template_color;

    &:hover,
    &:focus {
        background: $template_hover_color;
        color: #ffffff;
        border-color: $template_hover_color;
        box-shadow: unset;
    }
    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):active:focus,
    .show > &.dropdown-toggle:focus {
        box-shadow: unset;
    }
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    .show > &.dropdown-toggle {
        background: $template_color;
        color: #ffffff;
        border-color: $template_color;
    }
    &.disabled,
    &:disabled {
        color: #ffffff;
        opacity: 1;
        background-color: $template_color;
        border-color: $template_color;
        &:hover,
        &:focus {
            background: $template_hover_color;
            color: #ffffff;
            border-color: $template_hover_color;
            box-shadow: unset;
        }
    }
}

/*owl carousel btn*/
.owl-theme {
    .owl-nav {
        margin-top: 0;

        [class*="owl-"] {
            position: absolute;
            left: 40px;
            top: 45%;
            font-size: 30px !important;
            width: 60px;
            height: 60px;
            text-align: center;
            color: #ffffff !important;
            line-height: 50px !important;
            margin: 0;
            background: rgba(0, 0, 0, 0.5) !important;
            border-radius: 30px !important;
            transition: 0.4s;
        }

        [class*="owl-"]:hover {
            background: $template_color !important;
            color: #ffffff !important;
        }
        .owl-next {
            left: auto;
            right: 40px;
            cursor: pointer;
        }
        .owl-prev {
            transform: rotate(180deg);
            line-height: 50px;
            cursor: pointer;
        }
    }

    .owl-dots {
        line-height: 0.1;
        margin-top: 40px !important;

        .owl-dot {
            span {
                width: 15px !important;
                height: 15px !important;
                margin: 0 5px !important;
                background: #d7d7d7 !important;
                border-radius: 50% !important;
                transition: 0.4s !important;
            }
            &:hover span,
            &.active span {
                background: $template_color !important;
            }
        }
    }
}
/*form control*/
.form-control {
    height: 45px;
    padding: 15px;
    font-size: 15px;
    color: #000000;
    background-color: #eeeeee;
    border: none;
    border-radius: 0;
}
.form-control:focus {
    color: #495057;
    background-color: #ffffff;
    border-color: #eeeeee;
    outline: 0;
    box-shadow: unset;
}
/*section title*/
.section-title {
    text-align: center;
    margin: {
        bottom: 60px;
        top: -8px;
    }
    font-family: $font_family2;

    span {
        color: $template_color;
        display: block;
        text-transform: uppercase;
        margin-bottom: 10px;
        font: {
            size: 14px;
            weight: 700;
        }
    }
    h2 {
        font: {
            size: 30px;
            weight: 300;
        }
        margin: {
            bottom: 15px;
        }

        b {
            font-weight: 700;
        }
    }
    p {
        font-size: 16px;
        max-width: 535px;
        margin: 0 auto;
    }
}
/*================================================
Navbar Area CSS
=================================================*/
.navbar {
    position: fixed;
    width: 100%;
    height: auto;
    padding: 20px 0;
    left: 0;
    top: 0;
    background-color: transparent !important;
    z-index: 999;

    &.navbar-light.is-sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        padding: 15px 0;
        background-color: #ffffff !important;
        box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    }
}
.navbar-light {
    .navbar-brand {
        color: #ffffff;
        font-size: 28px;
        font-weight: 500;
        font-family: $font_family2;
        cursor: pointer;

        span {
            font-weight: 700;
            color: $template_color;
        }

        &:hover {
            color: #ffffff;
        }
    }

    .navbar-nav {
        .nav-item {
            padding: 0 14px;

            .nav-link {
                color: #ffffff;
                padding: 0;
                font-family: $font_family2;
                font-weight: 500;
                font-size: 14px;
                cursor: pointer;

                &:hover {
                    color: $template_color;
                }

                span {
                    position: relative;
                    display: inline-block;
                    width: 35px;
                    height: 3px;
                    background: $template_color;
                    right: 0;
                    top: -3px;
                    transition: 0.4s;

                    &::before,
                    &:after {
                        content: "";
                        width: 25px;
                        height: 3px;
                        background: $template_color;
                        position: absolute;
                        right: 0;
                        transition: 0.4s;
                    }
                    &::before {
                        top: -7px;
                    }
                    &::after {
                        bottom: -7px;
                    }
                }

                &:hover span::before,
                &:hover span::after {
                    width: 35px;
                }
                &.active {
                    color: $template_color;
                }
            }

            &.active .nav-link {
                color: $template_color;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }

    &.is-sticky {
        .navbar-brand {
            color: #222222;
        }

        .navbar-nav {
            .nav-item {
                .nav-link {
                    color: #222222;

                    &:hover {
                        color: $template_color;
                    }
                    &.active {
                        color: $template_color;
                    }
                }

                &.active .nav-link {
                    color: $template_color;
                }
            }
        }
    }
}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner {
    height: 100vh;
    overflow: hidden;
    background: {
        color: #dddddd;
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    position: relative;
    z-index: 1;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #565656;
        opacity: 0.6;
        z-index: -1;
    }

    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: {
            position: center center;
            // image: url();
            repeat: repeat;
        }
        z-index: -1;
    }

    .scroll_down {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        height: 50px;
        width: 30px;
        z-index: 5;
        bottom: 30px;
        background-color: transparent;
        border: 2px solid #ffffff;
        border-radius: 20px;
        cursor: pointer;
        animation: movebounce 3s linear infinite;
        outline: none;
        &::before {
            position: absolute;
            top: 10px;
            left: 50%;
            content: "";
            width: 6px;
            height: 6px;
            margin-left: -3px;
            background-color: #fff;
            border-radius: 100%;
            animation: scroll_down_btn-animation 2s infinite;
            box-sizing: border-box;
        }
    }
}
button.modal-video-close-btn {
    cursor: pointer;
}
.item-bg-one {
    background-image: url(https://mcusercontent.com/10d310a71fa47b547a2f36940/images/00ad1f04-30d3-49e2-963b-b97c04a86d9e.jpg);
}
.item-bg-two {
    background-image: url(https://mcusercontent.com/10d310a71fa47b547a2f36940/images/00ad1f04-30d3-49e2-963b-b97c04a86d9e.jpg);
}
.item-bg-three {
    background-image: url(https://mcusercontent.com/10d310a71fa47b547a2f36940/images/440c0d55-e378-4a7a-b5a4-df61f7d937e4.jpg);
}
.hero-content {
    text-align: center;
    max-width: 940px;
    margin: 50px auto 0;

    h5 {
        color: #ffffff;
        text-transform: uppercase;
        font: {
            size: 20px;
            weight: 300;
        }
        margin: 0;
    }
    h1 {
        color: #ffffff;
        font: {
            size: 70px;
            weight: 700;
        }
        margin: 15px 0;
        text-transform: uppercase;

        span {
            color: $template_color;
        }
    }
    p {
        color: #ffffff;
        font-size: 16px;
        margin-bottom: 30px;
    }

    .video-btn {
        font: {
            family: $font_family2;
            weight: 500;
            size: 15px;
        }
        color: #ffffff !important;
        margin-left: 7px;
        text-transform: capitalize;
        background: transparent;
        border: none;
        cursor: pointer;

        i {
            font-size: 38px;
            position: relative;
            top: 11px;
            margin-right: 7px;
        }
        &:hover,
        &:focus {
            color: $template_color !important;
        }
    }

    .btn {
        margin-right: 7px;
    }
}
.opacityZero {
    opacity: 0;
}
.opacityOne {
    opacity: 1;
}
.creative-box1 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;

    &::before {
        content: "";
        border-right: 50vw solid #ffffff;
        border-top: 60px solid transparent;
        bottom: 0;
        right: 0;
        position: absolute;
        height: 0;
        width: 0;
    }
    &::after {
        content: "";
        position: absolute;
        border-left: 50vw solid #ffffff;
        border-top: 60px solid transparent;
        bottom: 0;
        height: 0;
        left: 0;
        width: 0;
    }
}
.animation-box1 {
    position: absolute;
    left: 100px;
    bottom: 100px;
    animation: movebounce 3s linear infinite;
}
.animation-box2 {
    position: absolute;
    right: 150px;
    top: 150px;
    animation: movebounce 3s linear infinite;
}
.animation-box3 {
    position: absolute;
    left: 100px;
    top: 100px;
    animation: movebounce 3s linear infinite;
}
.animation-box4 {
    position: absolute;
    right: -260px;
    bottom: -260px;
    animation: movebounce 3s linear infinite;
}
.animation-box5 {
    position: absolute;
    right: -150px;
    bottom: -150px;
    z-index: -1;
}
.animation-box6 {
    position: absolute;
    right: 5px;
    bottom: 15px;
    z-index: -1;
    animation: movebounce 3s linear infinite;
}
.animation-box7 {
    position: absolute;
    left: 50%;
    bottom: 50px;
    z-index: -1;
    animation: movebounce 3s linear infinite;
}
@keyframes movebounce {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(15px);
    }
    100% {
        transform: translateY(0px);
    }
}
@keyframes moveleftbounce {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(15px);
    }
    100% {
        transform: translateX(0px);
    }
}
@keyframes scroll_down_btn-animation {
    0% {
        transform: translate(0, 0);
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    80% {
        transform: translate(0, 20px);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
@-webkit-keyframes scroll_down_btn-animation {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    80% {
        -webkit-transform: translate(0, 20px);
        transform: translate(0, 20px);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
.wave_box {
    height: 0;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    .wave {
        background: url(../../assets/img/wave.png) repeat-x;
        position: absolute;
        bottom: 0;
        width: 6400px;
        left: 0;
        height: 90px;
        animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
        transform: translate3d(0, 0, 0);
        &:nth-of-type(2) {
            bottom: -30px;
            animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
                swell 7s ease -1.25s infinite;
            opacity: 1;
        }
    }
}
@keyframes wave {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: -1600px;
    }
}
@keyframes swell {
    0%,
    100% {
        transform: translate3d(0, -25px, 0);
    }
    50% {
        transform: translate3d(0, 5px, 0);
    }
}

/*================================================
Subscribe Area CSS
=================================================*/
.subscribe-area {
    background: $template_color;
    padding-top: 50px;
    font-family: $font_family2;
    padding-bottom: 50px;
    position: relative;

    p {
        color: #ffffff;
        margin-bottom: 0;
        font-size: 24px;
    }
    position: relative;

    .form-control {
        background: transparent;
        border: 1px solid #ffffff;
        border-radius: 30px;
        color: #ffffff;

        &::placeholder {
            color: #ffffff;
        }
    }

    // button {
    //     position: absolute;
    //     right: 10px;
    //     top: 0;
    //     height: 45px;
    //     width: 75px;
    //     background: #ffffff;
    //     border: none;
    //     border-radius: 30px;
    //     font-size: 25px;
    //     cursor: pointer;
    //     line-height: 50px;
    //     color: $template_color;
    // }
    .validation-danger,
    .validation-success {
        color: #ffffff;
        margin-top: 10px;
    }

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 25px;
        background: {
            image: url(../../assets/img/border.png);
            position: center center;
            repeat: repeat;
            size: contain;
        }
        transform: rotate(540deg);
    }
}

/*================================================
Welcome Area CSS
=================================================*/
.single-box {
    text-align: center;
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    padding: 30px 20px;
    background: #ffffff;
    position: relative;
    transition: 0.4s;

    i {
        font-size: 50px;
        color: #999999;
        transition: 0.4s;
    }
    &:hover i {
        color: $template_color;
    }
    h3 {
        font: {
            size: 20px;
            weight: 500;
        }
        margin: {
            bottom: 15px;
            top: 20px;
        }
    }
    p {
        margin-bottom: 0;
    }

    &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 3px;
        border-radius: 0 0 5px 5px;
        left: 0;
        background: $template_color;
        bottom: 0;
        transition: 0.4s;
    }
    &:hover::before {
        width: 100%;
    }
    &:hover {
        transform: translateY(-10px);
    }
}

/*================================================
About Area CSS
=================================================*/
.about-area {
    position: relative;
    overflow: hidden;
    z-index: 1;

    .row {
        position: relative;
    }

    .animation-box2 {
        right: 80px;
        top: 50px;
    }
}
.about-image {
    position: absolute;
    left: -90px;
    top: -50px;
}
.about-title {
    h3 {
        font-size: 30px;
        margin: 0 0 10px;
        font-weight: 400;
    }
    h5 {
        font-size: 18px;
        text-transform: capitalize;
        font-weight: 300;
        margin: 0 0 15px;
        span {
            color: $template_color;
            font-weight: 400;
        }
    }
    p {
        margin-bottom: 0;
    }
}
.our-skill {
    margin-top: 25px;

    .single-progress {
        .progress-title {
            font-size: 16px;
            margin: 0 0 8px;
        }
        .progress {
            height: 10px;
            background: #ffffff;
            border-radius: 10px;
            box-shadow: none;
            margin-bottom: 20px;
            overflow: visible;

            .progress-bar {
                box-shadow: none;
                position: relative;
                background: $template_color;
                border-radius: 10px;
                animation: animate-positive 2s;
            }
            .progress-value {
                font-size: 15px;
                font-weight: 400;
                color: #222222;
                font-family: $font_family2;
                position: absolute;
                top: -28px;
                right: 0;
            }
        }
        &:nth-child(4) .progress {
            margin-bottom: 0;
        }
    }
}
@-webkit-keyframes animate-positive {
    0% {
        width: 0;
    }
}
@keyframes animate-positive {
    0% {
        width: 0;
    }
}
.our-mission {
    p {
        margin: {
            bottom: 0;
            top: 20px;
        }
    }
}
.why-choose-us {
    text-align: center;
    background: #ffffff;
    margin-top: 20px;

    .single-choose {
        padding: 20px 15px;

        i {
            font-size: 35px;
            color: #777777;
            transition: 0.4s;
        }
        &:hover i {
            color: $template_color;
        }
        h5 {
            font-size: 16px;
            font-weight: 500;
            margin: {
                bottom: 0;
                top: 8px;
            }
        }
    }

    .col-lg-4:nth-child(1) .single-choose {
        border-bottom: 1px solid #eeeeee;
    }
    .col-lg-4:nth-child(2) .single-choose {
        border: {
            bottom: 1px solid #eeeeee;
            right: 1px solid #eeeeee;
            left: 1px solid #eeeeee;
        }
    }
    .col-lg-4:nth-child(3) .single-choose {
        border-bottom: 1px solid #eeeeee;
    }
    .col-lg-4:nth-child(5) .single-choose {
        border: {
            right: 1px solid #eeeeee;
            left: 1px solid #eeeeee;
        }
    }
}

/*================================================
Services Area CSS
=================================================*/
.services-area {
    position: relative;
    overflow: hidden;
    z-index: 1;
    padding-bottom: 70px;
    background: {
        image: url(../../assets/img/bg.png);
        attachment: fixed;
        repeat: repeat;
    }
}
.single-services {
    padding: 30px 20px;
    background: #ffffff;
    position: relative;
    border-radius: 5px;
    margin-bottom: 30px;
    transition: 0.4s;

    i {
        font-size: 45px;
        color: #999999;
        transition: 0.4s;
    }
    &:hover i {
        color: $template_color;
    }
    h3 {
        font: {
            size: 20px;
            weight: 500;
        }
        margin: {
            bottom: 15px;
            top: 20px;
        }
    }
    p {
        margin-bottom: 0;
    }
    .bg-number {
        position: absolute;
        right: 20px;
        top: 10px;
        font-size: 54px;
        color: $template_color;
        opacity: 0.25;
        transition: 0.4s;
    }

    &:hover .bg-number {
        opacity: 1;
    }

    &:hover {
        transform: translateY(-10px);
        box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    }
}

/*================================================
Team Area CSS
=================================================*/
.team-area {
    position: relative;
    overflow: hidden;

    .animation-box4 {
        right: -300px;
        bottom: -300px;
    }
}
.single-team {
    text-align: center;

    ul {
        margin: 0;
        list-style-type: none;
        display: inline-block;
        background: $template_color;
        position: relative;
        top: -20px;
        padding: 6px 30px;
        border-radius: 30px;

        li {
            display: inline-block;

            a {
                color: #ffffff;
                font-size: 15px;
                margin: 0 -3px;
                width: 33px;
                border-radius: 50%;
                border: 1px solid transparent;
                height: 33px;
                text-align: center;
                line-height: 33px;

                &:hover {
                    border-color: #ffffff;
                    background: #ffffff;
                    color: $template_color;
                }
            }
        }
    }
    h3 {
        font: {
            weight: 500;
            size: 20px;
        }
        margin: {
            top: 6px;
            bottom: 6px;
        }
    }
    span {
        display: block;
        font: {
            size: 16px;
            weight: 500;
        }
        color: #999999;
    }
}

/*================================================
Portfolio Area CSS
=================================================*/
.portfolio-area {
    padding-bottom: 60px;
}
.single-work {
    margin-bottom: 40px;
    .work-image {
        display: block;
        position: relative;

        &::before {
            content: "";
            background: $template_color;
            position: absolute;
            left: 0;
            top: 0;
            width: 0;
            height: 100%;
            transition: 0.4s;
        }
        &:hover::before,
        &:focus::before {
            width: 100%;
        }
        .popup-btn {
            position: absolute;
            left: 0;
            top: 50%;
            text-align: center;
            right: 0;
            cursor: pointer;
            color: #ffffff;
            border: 2px solid #ffffff;
            width: 45px;
            margin: 0 auto;
            height: 45px;
            line-height: 45px;
            border-radius: 50%;
            margin-top: 0;
            opacity: 0;
            visibility: hidden;
            &:hover {
                background: #ffffff;
                color: $template_color;
            }
        }
        &:hover .popup-btn,
        &:focus .popup-btn {
            opacity: 1;
            visibility: visible;
            margin-top: -15px;
        }
    }
    h3 {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 500;
        margin: 25px 0 0;
        line-height: 12px;
    }
}

/*================================================
Features Area CSS
=================================================*/
.features-area {
    position: relative;
    overflow: hidden;
    z-index: 1;
    background: $template_color;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        opacity: 0.2;
        width: 100%;
        background: url(../../assets/img/white-waves.png);
    }
}
.features-img img {
    border-radius: 10px;
}
.single-features {
    position: relative;
    margin-bottom: 30px;
    padding-left: 90px;

    .icon {
        position: absolute;
        left: 0;
        top: 0;
        width: 70px;
        color: $template_color;
        height: 70px;
        text-align: center;
        line-height: 70px;
        background: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 50%;
        font-size: 30px;
        transition: 0.4s;
    }
    &:hover .icon {
        background: transparent;
        color: #ffffff;
    }
    h3 {
        font-size: 20px;
        color: #ffffff;
        margin-bottom: 15px;
    }
    p {
        color: #fff;
        margin-bottom: 0;
        font-weight: 400;
    }
    &:nth-child(3) {
        margin-bottom: 0;
    }
}

/*================================================
Feedback Area CSS
=================================================*/
.feedback-area {
    position: relative;
    overflow: hidden;
}
.single-feedback {
    background: #ffffff;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.06);
    padding: 20px;
    border-radius: 5px;
    margin-top: 25px;
    z-index: 1;
    position: relative;
    transition: 0.4s;

    &:hover {
        background: $template_color;
    }
    .client-info {
        position: relative;
        padding-left: 118px;
        margin-bottom: 20px;

        .img {
            position: absolute;
            left: 0;
            top: -45px;
            width: 100px;
            img {
                border-radius: 0 20px 0 0;
            }
        }
        h4 {
            font-size: 20px;
            font-weight: 500;
            -moz-transition: 0.4s;
            -webkit-transition: 0.4s;
            transition: 0.4s;
        }
        span {
            display: block;
            font-size: 14px;
            color: #828893;
            margin-top: 8px;
            transition: 0.4s;
        }
    }
    p {
        transition: 0.4s;
        margin-bottom: 0;
    }
    &:hover p,
    &:hover .client-info h4,
    &:hover .client-info span {
        color: #fff;
    }
    i {
        font-size: 104px;
        position: absolute;
        top: 50%;
        left: 50%;
        color: #6f8ba4;
        z-index: -1;
        margin-top: -6px;
        margin-left: -70px;
        opacity: 0.07;
        transition: 0.4s;
    }
    &:hover i {
        color: #ffffff;
        opacity: 0.14;
    }
}

/*================================================
Pricing Area CSS
=================================================*/
.tab-slider-nav {
    .tab-slider {
        .nav-tabs {
            display: block;
            text-align: center;
            margin-bottom: 40px;
            border: none;

            a {
                display: inline-block;
                background: #eeeeee;
                border: none;
                border-radius: 30px;
                margin: 0 5px;
                font: {
                    weight: 600;
                    size: 15px;
                }
                padding: 9px 35px;
                color: #686868;

                &.active {
                    background: $template_color;
                    color: #ffffff;
                }
            }
        }
    }
}
.pricing-table {
    text-align: center;
    background-image: url(../../assets/img/bg.png);
    padding-bottom: 35px;
    border-radius: 5px;
    box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
    border: 1px solid transparent;
    transition: 0.4s;

    .pricing-header {
        padding: 40px 20px;
        border-bottom: 1px solid #e4e4e4;
        transition: 0.4s;
        font: {
            size: 18px;
            weight: 700;
            family: $font_family2;
        }
        text-transform: uppercase;
    }
    .price {
        font: {
            size: 34px;
            weight: 700;
        }
        margin: {
            top: 25px;
            bottom: 25px;
        }
        color: $template_color;
    }
    .pricing-features {
        padding: 0;
        margin: 0 0 35px;
        list-style-type: none;

        li {
            font: {
                size: 15px;
                weight: 400;
            }
            color: #777777;
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .btn-primary {
        background: transparent;
        text-transform: uppercase;
        color: #777777;
        border: 1px solid $template_color;
        &:hover,
        &:focus {
            background: $template_hover_color !important;
            border-color: $template_hover_color !important;
        }
    }
    &.active,
    &:hover {
        border: 1px solid $template_color;
        .pricing-header {
            background: $template_color;
            border-color: $template_color;
            color: #ffffff;
        }
        .btn-primary {
            background: $template_color;
            color: #ffffff;
        }
    }
}

/*================================================
Blog Area CSS
=================================================*/
.blog-area {
    padding-bottom: 70px;
}
.single-blog-post {
    margin-bottom: 30px;

    .post-thumbnail {
        overflow: hidden;

        a {
            display: block;
            img {
                transition: 0.4s;
            }
        }
    }

    &:hover .post-thumbnail a img {
        transform: scale(1.3);
    }

    .post-content {
        margin-top: 25px;
        h3 {
            font: {
                size: 20px;
                weight: 700;
            }
            line-height: 30px;
            margin-bottom: 15px;
            a {
                color: #000000;
                &:hover {
                    color: $template_color;
                }
            }
        }
        .read-more {
            font-size: 15px;
            color: #777777;
            &:hover {
                color: $template_color;
            }
        }
    }
}

/*================================================
Work With Us Area CSS
=================================================*/
.work-with-us {
    background: {
        color: $template_color;
    }
    z-index: 1;
    text-align: center;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        opacity: 0.2;
        top: 0;
        width: 100%;
        height: 100%;
        background: url(https://mcusercontent.com/10d310a71fa47b547a2f36940/images/440c0d55-e378-4a7a-b5a4-df61f7d937e4.jpg);
        z-index: -1;
    }
    h3 {
        font: {
            size: 40px;
            weight: 700;
        }
        color: #ffffff;
        margin: {
            bottom: 12px;
            top: -7px;
        }
    }
    span {
        font-size: 20px;
        color: #ffffff;
        display: block;
        margin-bottom: 25px;
    }
    .btn-primary {
        color: orange;
        background-color: #ffffff;
        border: 1px solid #ffffff;
        &:hover,
        &:focus {
            color: #ffffff;
            background: transparent;
        }
    }
}

/*================================================
Partner Area CSS
=================================================*/
.partner-area {
    text-align: center;
    overflow: hidden;
    position: relative;
    .item a {
        display: block;
        position: relative;
        z-index: 1;
    }
    .owl-carousel .owl-item img {
        width: 140px;
    }
}

/*================================================
Contact Area CSS
=================================================*/
.contact-box {
    box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
    padding: {
        left: 115px;
        right: 30px;
        top: 25px;
        bottom: 25px;
    }
    position: relative;

    .icon {
        position: absolute;
        left: 25px;
        top: 30px;
        width: 70px;
        height: 70px;
        line-height: 68px;
        font-size: 35px;
        text-align: center;
        border-radius: 50%;
        color: $template_color;
        border: 1px solid $template_color;
        transition: 0.4s;
    }
    &:hover .icon {
        background: $template_color;
        color: #ffffff;
    }
    h4 {
        font-size: 18px;
        margin-bottom: 12px;
    }
    p {
        margin: 0;
        line-height: 25px;
        font-weight: 400;
        a {
            color: #777777;
            &:hover {
                color: $template_color;
            }
        }
    }
}
.get-in-touch {
    margin-top: 40px;
    h3 {
        font-size: 18px;
        padding-bottom: 10px;
        margin-bottom: 20px;
        border-bottom: 1px solid #eeeeee;
    }
    p {
        margin-bottom: 20px;
    }
    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        li {
            display: inline-block;
            a {
                color: $template_color;
                margin-right: 5px;
            }
        }
    }
}

#contactForm {
    margin-top: 40px;

    .form-control {
        background: transparent;
        border: 1px solid #eeeeee;
        border-radius: 5px;
    }
    .list-unstyled li {
        color: red;
    }
    #msgSubmit {
        margin: 0;
        &.text-left {
            margin-top: 20px;
            font-size: 18px;
        }
    }
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
    padding: 30px 0;
    background: #121314;
    p {
        font-size: 14px;
        margin-bottom: 0;
        color: #d5d5d5;
    }
    ul {
        text-align: right;
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
    li {
        display: inline-block;
        position: relative;
        font-size: 14px;
        padding-left: 17px;
        &::before {
            content: "";
            position: absolute;
            left: 5px;
            top: 5px;
            background: #ffffff;
            width: 2px;
            height: 12px;
        }
        &:first-child::before {
            display: none;
        }
        a {
            color: #d5d5d5;
            &:hover {
                color: $template_color;
            }
        }
    }
}

/*================================================
Right Side Modal CSS
=================================================*/
.modal.right {
    .modal-dialog {
        position: fixed;
        margin: auto;
        width: 520px;
        height: 100%;
        transform: translate3d(0%, 0, 0);

        .modal-content {
            height: 100%;
            overflow-y: auto;
            border-radius: 0;
            border: none;

            .modal-body {
                padding: 20px;

                p {
                    margin-top: 20px;
                }

                ul {
                    padding: 0;
                    margin: 0;
                    list-style-type: none;
                }

                .modal-contact-info {
                    margin-top: 30px;
                    margin-bottom: 20px;

                    li {
                        position: relative;
                        padding-left: 45px;
                        font-size: 18px;
                        margin-bottom: 25px;

                        b {
                            display: block;
                        }
                        i {
                            position: absolute;
                            left: 0;
                            top: 0;
                            font-size: 35px;
                            color: $template_color;
                        }
                    }
                }

                .social-links {
                    li {
                        display: inline-block;

                        a {
                            color: orange;
                            margin-right: 10px;
                            font-size: 18px;
                        }
                    }
                }

                .logo {
                    a {
                        color: #283a5e;
                        text-transform: uppercase;
                        font-size: 30px;
                        font-weight: 900;

                        span {
                            color: $template_color;
                        }
                    }
                }
            }
            .modal-header {
                border-bottom-color: transparent;
                background-color: transparent;
                padding: 20px 30px 0;

                .close {
                    color: #000000;
                    font-size: 35px;
                    outline: 0 !important;
                    transition: 0.4s;

                    &:not(:disabled):not(.disabled):focus,
                    &:not(:disabled):not(.disabled):hover {
                        color: $template_color;
                        text-decoration: none;
                        opacity: 1;
                    }
                }
            }
        }
    }
    &.fade {
        .modal-dialog {
            right: -420px;
            transition: opacity 0.3s linear, right 0.3s ease-out;
        }
        &.show {
            .modal-dialog {
                right: 0;
            }
        }
    }
}

/*================================================
Got Top CSS
=================================================*/
.ScrollUpButton__Container.ScrollUpButton__Toggled:hover {
    transition: 0.4s !important;
    background-color: $template_color !important;
}
